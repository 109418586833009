<template>
	<div style="width: ; :100vw ">
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" class="mb-24">
				<a-row :gutter="24" type="flex" align="stretch" style="overflow: hidden">
					<a-card :bordered="false" class="header-solid h-full" style="width: 100vw" :bodyStyle="{ padding: '24px' }">
						<!-- 兑换码 -->
						<div class="code">
							<img src="../assets/imgs/jiangbei.png" width="150" height="150" alt="" />
							<div class="rule" @mouseover="introduce = !introduce">规则介绍 <a-icon type="question-circle" /></div>
							<p><span style="color: #fff">兑换Ai积分</span></p>
							<a-input-search
								style="width: 380px"
								placeholder="请输入卡密"
								enter-button="立即兑换"
								size="large"
								@search="onSearch" />
							<a-icon style="font-size: 50px; margin-left: 10px; color: #1890ff" type="shopping-cart" @click="toLink" />
							<!-- 规则介绍 -->
							<div class="introduce" id="introduce" v-show="introduce">
								<a-card class="praise_card">
									<div>1、卡密仅可使用一次，一经兑换后将无法再使用；</div>
									<div>2、兑换成功后可获得相应的Ai积分；</div>
									3、对于部分仅限新用户、新设备或需要满足其他领取条件的权益，您若不满足相应领取条件，将无法参与，兑换后跳转活动页面将会出现“不满足参与条件”等提示，由于您未能全面了解相应活动规则而导致的损失或权益失效，wow有权不承担任何责任；
									<div>
										<div>
											4、wow会使用包括但不限于第三方技术手段审核用户的参与活动行为，如发现账号违规、异常等非正常情况，wow有权立即取消用户的活动参与资格，并撤销用户通过本活动所获得的Ai积分，情况严重者将封号处理；
										</div>
										5、活动期间，因用户操作不当或用户所在地区网络故障等非wow平台的原因而导致用户无法参与活动或参与失败，wow不负任何法律责任；
									</div>
									<div>6、如在兑换、使用过程中如有任何疑问，请联系wow平台；</div>
									<div>7、活动规则解释权归wow所有。</div>
								</a-card>
							</div>
						</div>

						<div class="content">
							<!-- 创作额度 -->
							<div v-for="(item, index) in creationQuota" :key="index">
								<div class="creat">
									<div class="create_box">
										<div>
											<img :src="item.img" width="80" height="80" alt="" />
										</div>
										<div class="flex ml-30" style="margin-top: 10px">
											<div>
												<span style="font-size: 30px">{{ item.number }}</span>
											</div>
											<div>{{ item.text }}</div>
										</div>
									</div>
								</div>
							</div>
							<!-- 权益兑换 -->
							<div v-for="(item, index) in equityExchange" :key="item.title" style="margin-top: 20px">
								<div class="exchange">
									<div class="title">
										<div style="width: 70%; position: relative">
											<p>
												<span class="titile_text">{{ item.title }}</span
												><span class="rule" v-show="item.rule == 1" @click="welfare = true">规则介绍</span>
											</p>
											<p class="content" style="height: 63px">{{ item.content }}</p>
											<!-- <p class="one"><span class="oneContent">01</span> 01</p> -->
										</div>
										<div style="width: 150px; height: 100px">
											<img :src="item.image" width="150" height="100" alt="" />
										</div>
									</div>
									<div class="button">
										<p>
											Ai积分：<span style="font-size: 18px; color: #1890ff; font-weight: bolder"
												>{{ item.limit }}
											</span>
										</p>
										<a-button
											size="small"
											style="border: 1px solid #1890ff; color: #1890ff"
											@click="handleButton(item.button)"
											>{{ item.button }}
										</a-button>
									</div>
								</div>
							</div>
							<div class="gift">
								<img src="../assets/imgs/liwu.png" width="25" height="25" alt="" />
								<p>权益中心</p>
							</div>
						</div>
					</a-card>
				</a-row>
				<!-- 福利传递活动规则 -->
				<div class="welfare" v-show="welfare">
					<a-card class="welfare_card">
						<div class="flex">
							<h6>福利传递活动规则</h6>
							<a-icon class="icon" @click="close('welfare')" type="close-circle" />
						</div>
						<div>
							好友首次注册wow后，使用您的卡密进行兑换。好友将获得88Ai积分奖励，而您也将获得相同积分的奖励！
							分享方式多样，可以通过福利传递底部的其他方式进行邀请，如微信好友、朋友圈分享链接等具体操作步骤：
						</div>
						<br />
						<p>1.登录账号，进入福利传递分享页面；</p>
						<p>2.复制你的专属卡密并分享给好友；</p>
						<p>3.好友在权益兑换页面输入你的卡密；</p>
						<p>4.好友成功兑换后，你和好友均可获得88Ai积分的奖励。</p>
						<p>注意事项：</p>
						<p>1.卡密只能在单个账户上兑换一次，不得重复使用；</p>
						<p>2.可以分享卡密给任意好友，获得相应的奖励；</p>
						<p>3.输入卡密时，请确保准确无误，不可遗漏或错误输入任何部分；</p>
						<p>4.如有任何问题或疑问，请联系客服。本规则说明的解释权归本公司所有。</p>
					</a-card>
				</div>

				<!-- 夸一夸 -->
				<div class="praise" v-show="praise">
					<a-card class="praise_card">
						<div class="title">
							<p>夸一夸wowAi积分送给你</p>
							<span>100%得Ai积分</span>
							<a-icon class="icon" @click="close('praise')" type="close-circle" />
						</div>
						<span>在社交媒体发布产品相关测评和好评推文，可获取 200 Ai积分</span>

						<p class="bloder">1.各平台社媒分享发布wow内容~</p>
						<p>不限制平台发布哦，可任务选择任何社媒平台，以下是举例~</p>
						<div class="imgList">
							<div v-for="(item, index) in platformImg" :key="index">
								<img :src="item" width="40" height="40" alt="" />
							</div>
						</div>
						<p class="bloder">2.联系客服</p>
						<p>上传截图1张作品,扫码联系客服，过审后即可获得积分</p>
						<div class="img">
							<img src="../assets/imgs/kefu.jpg" width="100" height="150" alt="" />
						</div>
					</a-card>
				</div>
				<!-- 群聊 -->
				<div class="crowd" v-show="crowd">
					<a-card class="crowd_card">
						<a-icon style="color: #fff" class="icon" @click="close('crowd')" type="close-circle" />
						<img :src="wxImg" width="150" height="150" alt="" usemap="#plantmap" />
					</a-card>
				</div>
				<!-- 签到 -->
				<div class="sign" v-show="isSign">
					<a-card class="crowd_card">
						<a-icon class="icon" @click="close('sign')" type="close-circle" />
						<img src="../assets/imgs/qiandaoback.png" width="150" height="150" alt="" @click="jumpSign" />
					</a-card>
				</div>

				<!-- 好评  review-->
				<div class="platform" v-show="good">
					<a-card class="crowd_card">
						<a-icon class="icon" @click="close('good')" type="close-circle" />
						<img src="../assets/imgs/kefu.jpg" width="150" height="150" alt="" />
					</a-card>
				</div>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	import store from "@/store";
	export default {
		components: {},
		data() {
			return {
				classifyData: [],
				classifySelected: "",
				creationQuota: [
					{
						img: require("@assets/imgs/yue.png"),
						number: 0,
						text: "账号余额",
					},
					{
						img: require("@assets/imgs/jifen.png"),
						number: 0,
						text: "Ai积分",
					},
					//
					{
						img: require("@assets/imgs/time.png"),
						number: "无",
						text: "到期时间",
					},
				],

				equityExchange: [
					{
						title: "注册wow",
						image: require("@assets/imgs/zhuce.png"),
						content: "首次登录注册wow，即可获得 88 积分",
						limit: "88",
						button: "注册",
						backText: "01",
					},
					{
						title: "小程序好评",
						image: require("@assets/imgs/qiandao.png"),
						content: "登录小程序后，前往用户中心的“给个好评”，好评并评论后截图给客服，即可获得 88 积分",
						limit: "88",
						button: "好评",
						backText: "02",
					},
					{
						title: "每日签到",
						image: require("@assets/imgs/qiandao.png"),
						content: "每日来此签到，即可获得 8 积分",
						limit: "8",
						button: "签到",
						backText: "03",
					},
					{
						title: "加入社群",
						image: require("@assets/imgs/shequn.png"),
						content: "欢迎加入wow官方微信交流群！入群之后，联系客服即可获得 88 积分卡密",
						limit: "88",
						button: "点击加入",
						backText: "04",
					},
					// {
					//   title: "邀请好友-兑换码",
					//   image: "",
					//   content: "首次登录注册wow账号，即可获得5次创作额度快去注册吧!",
					//   limit: "5次",
					//   button: "复制兑换码",
					//   rule: 1,
					// },

					{
						title: "福利传递 邀请好友",
						image: require("@assets/imgs/yaoqing.png"),
						content: "邀请好友首次注册，通过邀请链接或卡密注册wow，您可获得 88 积分",
						limit: "88",
						button: "复制链接",
						rule: 1,
						link: "",
						backText: "05",
					},
					{
						title: "夸一夸wow",
						image: require("@assets/imgs/kuakua.png"),
						content: "在各平台发布夸夸wow，发的多领的多，每天每个账号可投稿一次",
						limit: "200",
						button: "去夸一夸",
						backText: "06",
					},
				],

				platformImg: [
					require("@assets/imgs/pengyouquan.png"),
					require("@assets/imgs/kauishou.png"),
					require("@assets/imgs/bili.png"),
					require("@assets/imgs/zhihu.png"),
					require("@assets/imgs/xiaohongshu.png"),
					require("@assets/imgs/douyin.png"),
				],
				wxImg: "",
				visible: false,
				praise: false,
				crowd: false,
				welfare: false,
				isSign: false,
				qrcodeValue: "",
				introduce: false,
				good: false,
			};
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
			...mapGetters("app", ["config"]),
		},
		created() {
			// this.getUserInfo();
			// 群聊

			this.wxImg = "https://quelianai023.oss-accelerate.aliyuncs.com" + this.config.wx_code;
			console.log("群聊", this.wxImg);
			this.creationQuota[0].number = this.userInfo.money || 0;
			this.creationQuota[1].number = this.userInfo.usable || 0;
			this.creationQuota[2].number = this.userInfo.vip_expiretime_text || "无";

			// 链接
			let id = this.$store.state.user.userInfo.id;
			let spm = id + ".1.0.4.3";
			// this.qrcodeValue = this.$BASE_API + `/h5/#/pages/role/init?scene=` + spm;
			let sign = window.location.search.replace(/\?/g, "");
			this.qrcodeValue = this.$BASE_API + `?${sign}#/?share=${spm}`;
			this.equityExchange[3].link = this.qrcodeValue;
			if (this.token != "") {
				this.checkSign();
			}
		},
		mounted() {
			if (this.token) {
				this.equityExchange[0].button = "已注册";
			}
		},
		methods: {
			...mapActions("user", ["getUserInfo"]),
			// 签到状态 checkSign
			checkSign() {
				this.$http("user.checkSign").then((res) => {
					if (res.code === 1) {
						if (res.msg == "未签到") {
							this.equityExchange[2].button = "签到";
						} else {
							this.equityExchange[2].button = "已签到";
						}
					}
				});
			},
			// 签到
			userSign() {
				this.$http("user.userSign").then((res) => {
					if (res.code === 1) {
						this.equityExchange[2].button = "已签到";
						this.isSign = true;

						// 个人信息立即刷新
						this.getUserInfo();
						this.creationQuota[1].number = this.userInfo.usable || 0;
					}
				});
			},
			jumpSign() {
				this.token ? this.$router.push("/drawing") : this.$message.error("请登录");
			},

			handleOk() {},

			handleButton(type) {
				switch (type) {
					case "点击加入":
						this.crowd = !this.crowd;
						break;
					case "去夸一夸":
						this.praise = !this.praise;
						break;
					case "复制链接":
						try {
							navigator.clipboard.writeText(this.qrcodeValue);
							this.$message.success("复制成功");
						} catch (err) {
							this.$message.error("复制失败");
						}
						break;
					case "注册":
						store.commit("user/setLoginPopup", true);
						break;
					case "签到":
						if (this.token) {
							this.isSign = true;
							this.userSign();
						} else {
							this.$message.error("请登录");
						}

						break;
					// platform
					case "好评":
						this.good = true;
						break;
				}
			},
			close(type) {
				switch (type) {
					case "welfare":
						this.welfare = !this.welfare;
						break;
					case "praise":
						this.praise = !this.praise;
						break;
					case "crowd":
						this.crowd = !this.crowd;
						break;
					case "sign":
						this.isSign = false;
						break;
					case "good":
						this.good = !this.good;
						break;
				}
			},
			onSearch(value) {
				this.$http("recharge.decryptCard", { crypto: value, platform: "Web" }).then((res) => {
					if (res.code === 1) {
						this.$message.success("兑换成功");
					}
				});
			},
			toLink() {
				let link = document.createElement("a");
				link.style.display = "none";
				link.href = "http://www.88jishou.com/links/9C0A2829A174FB84";
				link.target = "_blank";
				link.click();
			},
		},
	};
</script>

<style lang="scss" scoped>
	// 兑换码
	.code {
		width: 100%;
		height: 300px;
		border: 1px solid #e6dddd;
		// color: #fff;
		// 渐变色背景 57aaf8
		// background-image: linear-gradient(#79a9d6, #fff);
		background-image: url("../assets/imgs/free.png");
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		margin-bottom: 20px;
		position: relative;
		border-radius: 20px;
		// cursor: pointer;
		::v-deep .ant-input {
			font-weight: normal !important;
		}
		::v-deep .ant-btn,
		.ant-btn-group .ant-btn,
		.ant-radio-group .ant-radio-button-wrapper {
			font-weight: normal !important;
		}
		img {
			margin-top: 10px;
		}
		p {
			font-size: 28px;
			font-weight: bolder;
			color: #141414;
			// margin-top: 10px;
			margin: 0;
			padding: 0;
			span {
				color: #1890ff;
			}
		}
		.rule {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			color: #fff;
		}
		.rule:hover {
			.introduce {
				display: block;
			}
		}
		.introduce {
			width: 300px;
			height: 200px;
			color: #999;
			text-align: left;
			position: absolute;
			right: 10px;
			top: 40px;
			z-index: 200;
		}
	}

	.content {
		// width: 100%;
		display: flex;
		flex-direction: column;
		display: grid;
		grid-template-columns: repeat(3, minmax(200px, 600px));
		grid-template-rows: 30% 30% 30%;
		grid-row-gap: 40px;
		grid-column-gap: 20px;
		justify-content: space-around;
		align-items: center;
		flex-direction: row;
		position: relative;
		.gift {
			position: absolute;
			top: 230px;
			left: 0px;
			display: flex;
			p {
				font-size: 16px;
				color: black;
				font-weight: 700;
				// display: inline-block;
				line-height: 30px;
			}
		}
	}

	// 创作额度
	.creat {
		// width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid #d4cdcd;
		color: #999;
		padding: 50px;
		border-radius: 10px;
		.create_box {
			display: flex;
			cursor: pointer;
		}
		&:hover {
			// background: rgba(102, 133, 253, 0.3);
			box-shadow: 0px 0px 30px rgba(0, 66, 220, 0.3);
			transform: translateY(-5%);
		}
	}

	// 权益兑换
	.exchange {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		border: 1px solid #d4cdcd;
		color: black;
		padding: 20px;
		border-radius: 10px;
		// margin-bottom: 10px;
		cursor: pointer;
		&:hover {
			// background: rgba(102, 133, 253, 0.3);
			box-shadow: 0px 0px 30px rgba(0, 66, 220, 0.3);
			transform: translateY(-5%);
		}

		.title {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.titile_text {
				font-size: 20px;
				font-weight: 700;
				color: #141414;
			}
			.rule {
				display: inline-block;
				padding: 3px;
				text-align: center;
				// background: #7598b9;
				color: #1890ff;
				margin-left: 5px;
				//   border-radius: 5px;
			}
			p {
				color: #999;
			}
			.content {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 4;
				-webkit-box-orient: vertical;
			}
		}
		.button {
			width: 100%;
			height: 42px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// padding: 10px;
			.link {
				// display: inline-block;
				width: 200px;
				// text-align: center;
				// background: #7598b9;
				// color: #1890ff;
				// margin-right: 5px;
				// word-wrap: break-word;
				// word-break: normal;
			}
			p {
				color: #999;
			}
		}
	}

	// 福利传递活动规则
	.welfare {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		background: rgba(0, 0, 0, 0.65);
		.welfare_card {
			width: 35%;
			position: relative;
			// position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 10px;
			.icon {
				position: absolute;
				top: 15px;
				right: 15px;
			}
		}
	}

	// 夸一夸
	.praise {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		background: rgba(0, 0, 0, 0.65);
		.praise_card {
			width: 500px;
			position: relative;
			// position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 10px;
			.title {
				display: flex;
				span {
					// display: inline-block;
					margin-left: 10px;
					width: 90px;
					height: 25px;
					// background: #7598b9;
					color: #1890ff;
					text-align: center;
					line-height: 45px;
					border-radius: 5px 5px 5px 0px;
				}
				.icon {
					position: absolute;
					top: 15px;
					right: 15px;
				}
				p {
					font-size: 24px;
					font-weight: 700;
					color: #141414;
					margin-bottom: 0;
				}
				span {
				}
				.icon {
					display: inline-block;
					margin-right: 0px;
				}
			}
			.bloder {
				font-size: 16px;
				font-weight: bolder;
				color: #141414;
				margin-top: 30px;
				margin-bottom: 0;
			}
			.imgList {
				display: flex;
				justify-content: start;
				align-items: center;
				img {
					display: block;
					margin-left: 10px;
				}
			}
			.img {
				img {
					width: 200px;
					height: 200px;
				}
			}
		}
	}

	// 群聊
	.crowd {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		background: rgba(0, 0, 0, 0.65);
		.crowd_card {
			width: 310px;
			height: 400px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			background-image: url("../assets/imgs/fuli.png");
			background-repeat: no-repeat;
			background-size: cover;

			img {
				position: absolute;
				left: 82px;
				bottom: 45px;
			}
			.icon {
				position: absolute;
				top: 15px;
				right: 15px;
				cursor: pointer;
			}
			p {
				margin-top: 10px;
			}
		}
	}
	//
	.sign {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		background: rgba(0, 0, 0, 0.65);
		.crowd_card {
			width: 400px;
			height: 600px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.icon {
				position: absolute;
				top: 15px;
				right: 15px;
			}
			img {
				width: 100%;
				height: 98%;
				cursor: pointer;
			}
		}
	}
	.platform {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1000;
		overflow: auto;
		outline: 0;
		background: rgba(0, 0, 0, 0.65);
		.crowd_card {
			width: 250px;
			height: 250px;
			position: relative;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			.icon {
				position: absolute;
				top: 15px;
				right: 15px;
			}
			img {
				width: 200px;
				height: 200px;
				position: absolute;
				bottom: 10px;
			}
		}
	}

	.one {
		font-size: 150px;
		position: absolute;
		top: -60px;
		left: 0;
		// background-image: -webkit-linear-gradient(top, #68a7c4, #92cfca, #fff);
		// -webkit-background-clip: text;
		// -webkit-text-fill-color: transparent;
		// opacity: 0.3;
		// background: linear-gradient(to right, rgb(163, 230, 235), rgb(219, 219, 223));
		// -webkit-background-clip: text;
		// background-clip: text;
		// color: transparent;

		color: #fff !important;
		text-align: center;
		opacity: 0.8;
		.oneContent {
			height: 110px;
			position: absolute;
			overflow: hidden;
			color: #d8f4f6 !important;
			//  #d8f4f6         #e8f9fa
		}
	}

	// ::v-deep.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button  {
	// 	border-top-right-radius: 0 !important;
	// }
	::v-deep .ant-btn-lg,
	.ant-btn-group-lg .ant-btn,
	.ant-radio-group-large .ant-radio-button-wrapper {
		border-radius: 0 !important;
	}
</style>
